import { useTranslations } from 'next-intl'
import { useMemo } from 'react'

import { getActiveContractTemplate } from '@/open-web/utils/purchaseFlowUtils'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { browserEnvs } from '@/shared/envs'
import type { EnrichedContractProduct } from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'
import { getBundledOrPreselectedAddons } from '@/shared/utils/addonsUtils'
import type { AddonItem } from '@/shared/utils/addonsUtils'
import { rejectNil } from '@/shared/utils/array'
import { logError } from '@/shared/utils/error'
import { ContractOrderError } from '@/shared/utils/errorClasses'

import { FinnishPrice, Price } from '../../Price'
import { PurchaseButton } from '../../PurchaseButton'
import { ContractCard } from '../ContractCard/ContractCard'

type ContractCardConnectorProps = {
  /**
   * Contract template with CB and CF data
   */
  contractProduct: EnrichedContractProduct
  /**
   * Order information
   */
  order?: number

  /**
   * Campaign configuration of given contractTemplate.
   */
  campaignConfiguration: CampaignConfigurationEntry

  /**
   * Contract card disabled state
   * (ie. CooP campaign if the CooP Member Id is not present or invalid)
   */
  isPurchaseDisabled?: boolean
}

export const ContractCardConnector = ({
  contractProduct,
  order,
  campaignConfiguration,
  isPurchaseDisabled,
}: ContractCardConnectorProps) => {
  const t = useTranslations('contractCard')
  const selectedContract = useGlobalStore((store) => store.selectedContract)
  const { setSelectedAddon } = selectedContract

  const { estimatedConsumption, shouldDisableContractSelect } = useGlobalStore(
    (store) => store.housingInfo,
  )

  const isRecommended = order === 0
  const isDisabled = shouldDisableContractSelect || isPurchaseDisabled

  const activeContractTemplate = useMemo(
    () =>
      getActiveContractTemplate(
        contractProduct,
        campaignConfiguration.campaignId,
        estimatedConsumption,
      ),
    [estimatedConsumption, contractProduct, campaignConfiguration.campaignId],
  )

  if (
    activeContractTemplate.customerType !== 'PRIVATE' &&
    activeContractTemplate.customerType !== 'ENTERPRISE'
  ) {
    throw logError(
      new ContractOrderError(
        `${activeContractTemplate.templateNo} has UNKNOWN customerType. Contract has bad configuration. Can not be sold!`,
      ),
    )
  }

  const contractCustomerType =
    activeContractTemplate.customerType?.toLocaleLowerCase() as CustomerType
  const selectedAddons = selectedContract[contractCustomerType].selectedAddons

  //For now we won't present preselected addons
  const addonItems = useMemo(
    () => getBundledOrPreselectedAddons(activeContractTemplate),
    [activeContractTemplate],
  )

  const handleAddonsSelectionChange = (addon: AddonItem) => {
    const value = selectedAddons?.[addon.addonType]?.value ? null : addon.tariffNo
    setSelectedAddon(addon.addonType, { value }, contractCustomerType)
  }

  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const PriceComponent = country === 'FI' ? FinnishPrice : Price

  const priceComponent = (
    <PriceComponent
      contractTemplate={activeContractTemplate}
      campaignConfiguration={campaignConfiguration}
      contractProduct={contractProduct}
      theme={isRecommended ? 'dark' : 'light'}
      priceBreakdownMode="withFooter"
      shouldShowPricePlaceholder
      isPriceBreakdownDisabled={isDisabled}
    />
  )

  const primaryButton = (
    <PurchaseButton
      campaignConfiguration={campaignConfiguration}
      contractProduct={contractProduct}
      contractTemplate={activeContractTemplate}
      invert={isRecommended}
      display="flex"
      justifyContent="center"
      alignItems="center"
      disabled={isDisabled}
    />
  )

  return (
    <ContractCard
      contractContent={{
        name: contractProduct.cfData.title || '',
        description: activeContractTemplate.cfData.description || '',
        uspList: rejectNil(activeContractTemplate.cfData.sellingPoints || []),
        currency: activeContractTemplate.currency,
        addons: activeContractTemplate.availableAddons,
        tariffElements: activeContractTemplate.tariffElements,
        customerType: activeContractTemplate.customerType,
      }}
      priceComponent={priceComponent}
      primaryButton={primaryButton}
      secondaryButtonHref={contractProduct.cfData.url}
      onSelectedAddonsChange={handleAddonsSelectionChange}
      selectedAddons={selectedAddons}
      secondaryButtonText={`${t('contractCardSecondaryButtonText')} ${
        contractProduct.cfData.title
      }`}
      bundledOrPreselectedAddons={addonItems}
      cardBadge={activeContractTemplate.cfData.badge}
      isRecommended={isRecommended}
      isDisabled={isDisabled}
    />
  )
}
